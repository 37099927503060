<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <common-conditions
      v-model="currentForm"
      :headers-ext="headers"
      :items="salesConditions"
      :validator="currentForm => {
        return currentForm.calcSalesTarget
          && currentForm.roundingMethod
          && currentForm.roundingDigit <= 0
      }"
      threshold-prefix="¥"
      :is-submitting="isSubmitting"
      :display-functions="displayFunctions"
      @submit="submit"
      @cancel="cancelForm"
      @edit="currentForm = { id: $event.id, ...$event.attributes }"
      @destroy="destroy($event)"
    >
      <template #input>
        <v-row class="mt-3">
          <v-col cols="6">
            <v-select
              :value="currentForm"
              :items="tableFilters"
              :item-value="item => {
                if (item.attributes) return { tableFilterId: item.id }
              }"
              :value-comparator="(a, b) => {
                if (a && b) return +a.tableFilterId === +b.tableFilterId
              }"
              item-text="attributes.name"
              label="卓フィルタ"
              placeholder="指定なし"
              persistent-placeholder
              no-data-text="選択可能なデータがありません"
              :suffix="currentForm.tableFilterId ? 'の卓の' : ''"
              clearable
              :menu-props="{ auto: true, offsetY: true }"
              @change="currentForm.tableFilterId = $event ? $event.tableFilterId : null"
              @click:clear="currentForm.tableFilterId = null"
            />
          </v-col>

          <v-col cols="6">
            <v-select
              :value="currentForm.calcSalesTarget"
              :items="calcSalesTargets"
              item-value="value"
              item-text="text"
              label="算出対象範囲"
              :menu-props="{ auto: true, offsetY: true }"
              :error="!currentForm.calcSalesTarget"
              @change="currentForm.calcSalesTarget = $event"
            >
              <template #selection="{ item }">
                <span>
                  <v-icon
                    v-if="item.icon"
                    left
                    small
                  >
                    {{ item.icon }}
                  </v-icon>
                  {{ item.text }}
                </span>
              </template>

              <template #item="{ item }">
                <v-list-item-avatar
                  v-if="item.icon"
                  size="20"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>

            <v-checkbox
              v-model="currentForm.withTax"
              label="大計"
              :ripple="false"
              class="mt-0 pt-0"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-select
              v-model="currentForm.roundingMethod"
              :items="roundingMethods"
              item-value="value"
              item-text="text"
              label="丸め処理"
              :error="!currentForm.roundingMethod"
              :menu-props="{ auto: true, offsetY: true }"
              mandatory
            />
          </v-col>

          <v-spacer />

          <v-col
            md="6"
            cols="12"
          >
            <v-slider
              :value="Math.abs(currentForm.roundingDigit)"
              :tick-labels="['0', '一', '十', '百', '千', '万']"
              :min="0"
              :max="5"
              label="丸める位"
              step="1"
              ticks="always"
              tick-size="4"
              :error="!(currentForm.roundingDigit <= 0)"
              @change="currentForm.roundingDigit = ($event * -1)"
            />
          </v-col>
        </v-row>
      </template>

      <template #group-header="{ groupBy, group }">
        <strong v-if="groupBy[0] === 'attributes.roundingMethod'">
          {{
            {
              round: '四捨五入',
              ceil: '切り上げ',
              floor: '切り捨て',
            }[group]
          }}
        </strong>

        <strong v-if="groupBy[0] === 'meta.calcSalesTargetName'">
          {{ group }}
        </strong>
      </template>
    </common-conditions>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api'
import { findIndex } from 'lodash'
import { mdiCashMarker, mdiHeartOutline } from '@mdi/js'
import SalesConditionApi from '@/api/admin/SalesCondition'
import TableFilterApi from '@/api/admin/TableFilter'
import useCompInit from '@/views/composable/useCompInit'
import CommonConditions from '@/views/components/pay_condition/CommonConditions.vue'

export default {
  components: {
    CommonConditions,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const isSubmitting = ref(false)
    const currentForm = ref({
      id: null,
      adjective: null,
      threshold: 0,
      calcSalesTarget: 'contributed',
      withTax: false,
      roundingMethod: 'round',
      roundingDigit: 0,
      tableFilterId: null,
    })
    const salesConditions = ref([])
    const tableFilters = ref([])
    const calcSalesTargets = [
      { icon: mdiCashMarker, value: 'contributed', text: '売上' },
      { icon: mdiHeartOutline, value: 'received', text: '受領' },
      { value: 'both', text: '両方' },
    ]

    const cancelForm = () => {
      currentForm.value = {
        id: null,
        adjective: null,
        threshold: 0,
        calcSalesTarget: 'contributed',
        withTax: false,
        roundingMethod: 'round',
        roundingDigit: 0,
        tableFilterId: null,
      }
    }

    const submit = async () => {
      isSubmitting.value = true

      const isUpdate = !!currentForm.value.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        adjective,
        threshold,
        calcSalesTarget,
        withTax,
        roundingMethod,
        roundingDigit,
        tableFilterId,
      } = currentForm.value

      const res = await SalesConditionApi[`${fnVerb}SalesCondition`]({
        id,
        adjective,
        threshold,
        calcSalesTarget,
        withTax,
        roundingMethod,
        roundingDigit,
        tableFilterId,
      })

      if (res?.data) {
        const { data } = res.data.salesCondition

        if (isUpdate) {
          const salesConditionIdx = findIndex(salesConditions.value, o => +o.id === +data.id)
          salesConditions.value.splice(salesConditionIdx, 1, data)
        } else {
          salesConditions.value.push(data)
        }

        vm.$toast.success(isUpdate ? '条件を更新しました' : '条件を追加しました')
      }

      cancelForm()
      isSubmitting.value = false
    }

    const destroy = async id => {
      const res = await SalesConditionApi.deleteSalesCondition(id)

      if (res) {
        const salesConditionIdx = findIndex(salesConditions.value, o => +o.id === +id)
        salesConditions.value.splice(salesConditionIdx, 1)
        vm.$toast.success('条件を削除しました')
      }
    }

    const getSalesConditions = async () => {
      const res = await SalesConditionApi.getSalesConditions()

      if (res?.data) {
        salesConditions.value = [...res.data.salesConditions.data]
      }
    }

    const getTableFilters = async () => {
      const res = await TableFilterApi.getTableFilters()

      if (res?.data) {
        tableFilters.value = [...res.data.tableFilters.data]
      }
    }

    initWith([
      getSalesConditions(),
      getTableFilters(),
    ])

    return {
      // data
      isLoading,
      isSubmitting,
      currentForm,
      salesConditions,
      tableFilters,
      roundingMethods: [
        { value: 'round', text: '四捨五入' },
        { value: 'ceil', text: '切り上げ' },
        { value: 'floor', text: '切り捨て' },
      ],
      headers: [
        {
          text: '算出対象範囲',
          value: 'meta.calcSalesTargetName',
        },
        {
          text: '丸め処理',
          value: 'attributes.roundingMethod',
          align: 'right',
        },
        {
          text: '丸める位',
          value: 'attributes.roundingDigit',
          align: 'left',
          groupable: false,
        },
      ],
      calcSalesTargets,
      displayFunctions: {
        'attributes.threshold': threshold => threshold.toLocaleString(),
        'attributes.roundingMethod': value => {
          return {
            round: '四捨五入',
            ceil: '切り上げ',
            floor: '切り捨て',
          }[value]
        },
        'attributes.roundingDigit': value => {
          if (value === 0) return ''

          return `${{
            1: '一',
            2: '十',
            3: '百',
            4: '千',
            5: '万',
          }[Math.abs(value)]}の位`
        },
      },

      // methods
      cancelForm,
      submit,
      destroy,
    }
  },
}
</script>
