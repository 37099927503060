var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('common-conditions',{attrs:{"headers-ext":_vm.headers,"items":_vm.salesConditions,"validator":function (currentForm) {
      return currentForm.calcSalesTarget
        && currentForm.roundingMethod
        && currentForm.roundingDigit <= 0
    },"threshold-prefix":"¥","is-submitting":_vm.isSubmitting,"display-functions":_vm.displayFunctions},on:{"submit":_vm.submit,"cancel":_vm.cancelForm,"edit":function($event){_vm.currentForm = Object.assign({}, {id: $event.id}, $event.attributes)},"destroy":function($event){return _vm.destroy($event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.currentForm,"items":_vm.tableFilters,"item-value":function (item) {
              if (item.attributes) { return { tableFilterId: item.id } }
            },"value-comparator":function (a, b) {
              if (a && b) { return +a.tableFilterId === +b.tableFilterId }
            },"item-text":"attributes.name","label":"卓フィルタ","placeholder":"指定なし","persistent-placeholder":"","no-data-text":"選択可能なデータがありません","suffix":_vm.currentForm.tableFilterId ? 'の卓の' : '',"clearable":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.currentForm.tableFilterId = $event ? $event.tableFilterId : null},"click:clear":function($event){_vm.currentForm.tableFilterId = null}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.currentForm.calcSalesTarget,"items":_vm.calcSalesTargets,"item-value":"value","item-text":"text","label":"算出対象範囲","menu-props":{ auto: true, offsetY: true },"error":!_vm.currentForm.calcSalesTarget},on:{"change":function($event){_vm.currentForm.calcSalesTarget = $event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('span',[(item.icon)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [(item.icon)?_c('v-list-item-avatar',{attrs:{"size":"20"}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}])}),_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"label":"大計","ripple":false},model:{value:(_vm.currentForm.withTax),callback:function ($$v) {_vm.$set(_vm.currentForm, "withTax", $$v)},expression:"currentForm.withTax"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.roundingMethods,"item-value":"value","item-text":"text","label":"丸め処理","error":!_vm.currentForm.roundingMethod,"menu-props":{ auto: true, offsetY: true },"mandatory":""},model:{value:(_vm.currentForm.roundingMethod),callback:function ($$v) {_vm.$set(_vm.currentForm, "roundingMethod", $$v)},expression:"currentForm.roundingMethod"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-slider',{attrs:{"value":Math.abs(_vm.currentForm.roundingDigit),"tick-labels":['0', '一', '十', '百', '千', '万'],"min":0,"max":5,"label":"丸める位","step":"1","ticks":"always","tick-size":"4","error":!(_vm.currentForm.roundingDigit <= 0)},on:{"change":function($event){_vm.currentForm.roundingDigit = ($event * -1)}}})],1)],1)]},proxy:true},{key:"group-header",fn:function(ref){
            var groupBy = ref.groupBy;
            var group = ref.group;
return [(groupBy[0] === 'attributes.roundingMethod')?_c('strong',[_vm._v(" "+_vm._s({ round: '四捨五入', ceil: '切り上げ', floor: '切り捨て', }[group])+" ")]):_vm._e(),(groupBy[0] === 'meta.calcSalesTargetName')?_c('strong',[_vm._v(" "+_vm._s(group)+" ")]):_vm._e()]}}]),model:{value:(_vm.currentForm),callback:function ($$v) {_vm.currentForm=$$v},expression:"currentForm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }