import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getSalesConditions() {
    return axiosIns.get('/admin/sales_conditions')
  },
  createSalesCondition({
    adjective,
    threshold,
    withTax,
    roundingMethod,
    roundingDigit,
    calcSalesTarget,
    tableFilterId,
  }) {
    const formData = serialize(snakecaseKeys({
      salesCondition: {
        adjective,
        threshold,
        withTax,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
        tableFilterId,
      },
    }))

    return axiosIns.post('/admin/sales_conditions', formData)
  },
  updateSalesCondition({
    id,
    adjective,
    threshold,
    withTax,
    roundingMethod,
    roundingDigit,
    calcSalesTarget,
    tableFilterId,
  }) {
    const formData = serialize(snakecaseKeys({
      salesCondition: {
        adjective,
        threshold,
        withTax,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
        tableFilterId,
      },
    }))

    return axiosIns.put(`/admin/sales_conditions/${id}`, formData)
  },
  deleteSalesCondition(id) {
    return axiosIns.delete(`/admin/sales_conditions/${id}`)
  },
}
